import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

import { useConfig } from './useConfig';

import storageUtils from '../../utils/storageUtils';
import { authActions } from '../../store/reducers/authReducer';
import { authApi } from '../api/authApi';
import { TAuthInfo } from '../types/authTypes';
import { ccpSelectors } from '../../store/selectors';

type TReturn = {
  login: (username: string, password: string) => void;
  logout: () => void;
  error: string;
  putAgentToOffline: () => void;
};

export const useAuth = (): TReturn => {
  const { env } = useConfig();
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { curr: contact } = useSelector(ccpSelectors.getVoiceChannelContact);

  useEffect(() => {
    if (window.location.hash) {
      const authToken = window.location.hash.split('#').pop();
      authToken && handleLogin(authToken);
    }
  }, [window.location.hash]);

  const login = async (username: string, password: string): Promise<void> => {
    //this const is used for login-dev endpoint, only for development purposes!
    try {
      const {
        data: { accessToken },
      } = await authApi.login(username, password);
      handleLogin(accessToken);
      setError('');
    } catch (error: any) {
      if (error && error.response && error.response.status === 500) {
        setError(t('error.invalidCredentials'));
      }
    }
  };

  const handleLogin = (token: string): void => {
    if (
      token.startsWith('401') ||
      token.startsWith('403') ||
      token.startsWith('500')
    ) {
      setError(t(`error.login.${token}`));
      return;
    }

    storageUtils.setAuthToken(token);
    const userInfo = JwtDecode(token) as TAuthInfo;

    dispatch(authActions.login(userInfo));
  };

  const logout = async () => {
    try {
      putAgentToOffline();
      fetch(`${env?.connectInstance}/logout`, {
        method: 'GET',
        credentials: 'include',
        mode: 'no-cors',
        keepalive: true,
      });
      const token = storageUtils.getAuthToken();
      storageUtils.clearLocalUserData();
      fetch(`${process.env.NX_BASE_URL}logout`, {
        method: 'POST',
        keepalive: true,
        headers: {
          Authorization: token,
        },
      });
      contact?.getInitialConnection().destroy();
      contact?.getAgentConnection().destroy();
      contact?.getActiveInitialConnection()?.destroy();
      (contact as any)?.clear();

      connect?.core?.terminate();
      dispatch(authActions.logout());
    } catch (error) {
      console.log(error);
    }
  };

  const putAgentToOffline = () => {
    connect.agent((agent) => {
      const states = agent.getAgentStates();
      const offlineStatus = states.filter(
        (state) => state.name === 'Offline'
      )[0];
      agent.setState(offlineStatus);
    });
  };


  return { login, logout, error, putAgentToOffline };
};
